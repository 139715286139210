import videojs from 'video.js';

export default () => ({
    container: null,
    player: null,
    aspectRatio: '16/9',

    init() {
        this.container = this.$root.closest('.video-container');
        this.aspectRatio = this.$root.dataset.aspectRatio || '16/9';

        this.initPlayer();
    },

    initPlayer() {
        if (this.$root.player || this.player) {
            return;
        }

        this.player = videojs(this.$root, {
            language: 'de',
            html5: {
                nativeControlsForTouch: this.$root.hasAttribute('controls'),
                nativeVideoTracks: this.$root.hasAttribute('controls'),
                vhs: {
                    overrideNative: !videojs.browser.IS_SAFARI,
                    useDevicePixelRatio: true,
                    useNetworkInformationApi: true,
                },
            },
        });

        this.dimensions();
        window.addEventListener('resize', () => this.dimensions());
    },

    dimensions() {
        if (this.player) {
            if (this.player.el().offsetHeight < this.container.offsetHeight) {
                this.player.el().style.width = 'auto';
                this.player.el().style.height = '100%';
            } else if (this.player.el().offsetWidth < this.container.offsetWidth) {
                this.player.el().style.width = '100%';
                this.player.el().style.height = 'auto';
            }
        }
    },
});
